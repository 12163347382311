<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-card-title> Atividades recentes </b-card-title>
      </div>
    </b-card-header>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <div v-else>
      <!-- timeline -->
      <b-card-body>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scrollable-container scroll-area profile-scroller"
          tagname="div"
        >
          <app-timeline class="mt-1">
            <template v-for="item in items">
              <app-timeline-item variant="primary">
                <b-list-group>
                  <b-list-group-item class="item-no-click">
                    <h5 v-html="item.name"></h5>
                    <b-row>
                      <b-col md="8" v-html="item.description"></b-col>
                      <b-col class="text-right" md="4">{{
                        item.formatedDate
                      }}</b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </app-timeline-item>
            </template>
          </app-timeline>
        </vue-perfect-scrollbar>
        <!--/ timeline -->
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
  BCardTitle,
  BListGroupItem,
  BListGroup,
  BSpinner,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BListGroupItem,
    BListGroup,
    BSpinner,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerTimelineEvents: types.CUSTOMER_TIMELINE_EVENTS,
    }),
    items: function () {
      var timeZone = "America/Sao_Paulo";
      var timeLine = this.customerTimelineEvents.map(function (element) {
        var utcDate = new Date(element.date);
        var options = {
          timeZone: timeZone,
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        var brazilianDate = utcDate
          .toLocaleString("pt-BR", options)
          .replace(",", "");
        element.formatedDate = brazilianDate;
        return element;
      });
      return _.orderBy(
        timeLine,
        ['order', function (element) {
          return new Date(element.date);
        }],
        ['desc', 'desc']
      );
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getCustomerTimelineEvents: types.GET_CUSTOMER_TIMELINE_EVENTS,
    }),
    get() {
      this.loading = true;
      this.getCustomerTimelineEvents(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as atividades recentes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.item-no-click:active {
  background-color: transparent !important;
}
.profile-scroller {
  max-height: 30rem;
}
</style>
