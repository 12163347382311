<template>
  <div>
    <user-address-sidebar />
    <user-personal-data-sidebar />
    <new-appointment-sidebar />
    <new-lead-sidebar v-if="profileType == type.PROFILE_TYPE_CONSULTANT" />
    <save-consultant-basic-information-sidebar
      v-if="profileType == type.PROFILE_TYPE_CONSULTANT"
    />
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <div v-else-if="user">
      <b-card class="about-card abbout-card-top">
        <!-- about -->
        <b-row class="justify-content-center m-1">
          <b-form-file
            ref="pic"
            v-model="profilePic"
            accept=".jpg,.jpeg,.png,.bmp,.tiff"
            style="display: none"
            @input="uploadPic"
          />
          <b-avatar
            v-if="user.profile_picture_url"
            :src="user.profile_picture_url"
            size="8rem"
            badge
            badge-variant="profile"
          >
            <template #badge>
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="$refs.pic.$el.querySelector('input[type=file]').click()"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </template>
          </b-avatar>
          <b-avatar
            v-else
            src="~@/assets/images/logo/futuro.svg"
            size="8rem"
            badge
            badge-variant="profile"
          >
            <template #badge>
              <b-button
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="$refs.pic.$el.querySelector('input[type=file]').click()"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </template>
          </b-avatar>
        </b-row>
        <b-row class="justify-content-center">
          <h4 class="mb-75">
            {{ user.name }}
          </h4>
        </b-row>
        <b-row class="justify-content-center">
          <span>{{ user.email }}</span>
        </b-row>
        <b-row class="justify-content-center">
          <span>{{ user.cell_phone | formatPhone }}</span>
        </b-row>
        <b-row class="justify-content-center m-1">
          <b-badge v-if="user.is_vip" pill class="vip-badge mr-2" style="width: 120px;">
            <b-row class="justify-content-center align-items-center">
              <img
                class="img_icon"
                style="margin-right: 10px;"
                src="~@/assets/images/svg/icn_premium.svg"
              />
              <span style="color: #dba800;">Cliente VIP</span>
            </b-row>
          </b-badge>
          <b-badge
            pill
            variant="hot-user"
            v-if="
              user.customer_status_id == status.CUSTOMER_STATUS_HOT ||
              user.customer_status_id == status.CUSTOMER_STATUS_LEAD_PN_HOT
            "
          >
            <img src="~@/assets/images/svg/icn_hot_active.svg" width="10" />
            Quente
          </b-badge>
          <b-badge
            pill
            variant="cold-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_COLD ||
              user.customer_status_id == status.CUSTOMER_STATUS_LEAD_PN_COLD
            "
          >
            <img src="~@/assets/images/svg/icn_cold_active.svg" width="10" />
            Frio
          </b-badge>
          <b-badge
            pill
            variant="disinterested-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_DISINTERESTED ||
              user.customer_status_id ==
                status.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED
            "
          >
            <img
              src="~@/assets/images/svg/icn_disinterested_active.svg"
              width="12"
            />
            Desinteressado
          </b-badge>
          <b-badge
            pill
            variant="scheduled_interview-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_EA ||
              user.customer_status_id == status.CUSTOMER_STATUS_LEAD_PN_EA
            "
          >
            Entrevista agendada
          </b-badge>
          <b-badge
            pill
            variant="done_interview-user"
            v-else-if="user.customer_status_id == status.CUSTOMER_STATUS_EF"
          >
            Entrevista feita
          </b-badge>
          <b-badge
            pill
            variant="done_interview-lead-consultant"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_LEAD_PN_EF
            "
          >
            Entrevista PN feita
          </b-badge>
          <b-badge
            pill
            variant="support-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_SUPPORT
            "
          >
            <img src="~@/assets/images/svg/icn_support.svg" width="10" />
            Suporte
          </b-badge>
          <b-badge
            pill
            variant="basic_sub-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_BASIC_SUB
            "
          >
            <img src="~@/assets/images/svg/icn_user_disable.svg" width="10" />
            Básico
          </b-badge>
          <b-badge
            pill
            variant="essential_sub-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_ESSENTIAL_SUB
            "
          >
            <img src="~@/assets/images/svg/icn_essential.svg" width="12" />
            Essencial
          </b-badge>
          <b-badge
            pill
            variant="elite_sub-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_ELITE_SUB
            "
          >
            <img src="~@/assets/images/svg/icn_premium.svg" width="12" />
            Elite
          </b-badge>
          <b-badge
            pill
            variant="done_interview-user"
            v-else-if="user.customer_status_id == status.CUSTOMER_STATUS_EP"
          >
            Entrevista paga
          </b-badge>
          <b-badge
            pill
            variant="done_financial_solution-user"
            v-else-if="
              user.customer_status_id ==
              status.CUSTOMER_STATUS_FINANCIAL_SOLUTION
            "
          >
            Solução financeira
          </b-badge>
          <b-badge
            pill
            variant="canceled_subscription-user"
            v-else-if="
              user.customer_status_id ==
              status.CUSTOMER_STATUS_CANCELED_SUBSCRIPTION
            "
          >
            Assinatura cancelada
          </b-badge>
          <b-badge
            pill
            variant="gestao-user"
            v-else-if="user.customer_status_id == status.CUSTOMER_STATUS_GESTAO"
          >
            Gestão financeira
          </b-badge>
          <b-badge
            pill
            variant="wait_contract_payment-user"
            v-else-if="
              user.customer_status_id ==
              status.CUSTOMER_STATUS_WAIT_CONTRACT_PAYMENT
            "
          >
            Aguardando contrato rodar
          </b-badge>
          <b-badge
            pill
            variant="done_financial_solution-user"
            v-else-if="
              user.customer_status_id == status.CUSTOMER_STATUS_LEAD_PN_SOLIDES
            "
          >
            Sólides
          </b-badge>
          <b-badge
            pill
            variant="done_financial_solution-user"
            v-else-if="
              user.customer_status_id ==
              status.CUSTOMER_STATUS_LEAD_PN_DISQUALIFIED
            "
          >
            Desclassificado
          </b-badge>
          <b-badge
            pill
            variant="transformed-user"
            v-else-if="
              user.customer_status_id ==
              status.CUSTOMER_STATUS_LEAD_PN_TRANSFORMED
            "
          >
            Transformado em PN
          </b-badge>
          <b-badge pill variant="undefined-user" v-else>
            A classificar
          </b-badge>
        </b-row>
        <b-row
          class="text-center m-1"
          v-if="profileType == type.PROFILE_TYPE_CUSTOMER"
        >
          <b-col cols="12">
            Valor LTV: <b>{{ user.ltv_value | toCurrency }}</b>
            <br />
            Poupa:
            <b>
              <span
                v-if="
                  user.customer_additionals &&
                  user.customer_additionals.monthly_savings
                "
              >
                {{ user.customer_additionals.monthly_savings | toCurrency }}
              </span>
              <span v-else> R$ 0,00 </span>
            </b>
          </b-col>
        </b-row>
        <template
          v-if="
            profileType == type.PROFILE_TYPE_CUSTOMER ||
            profileType == type.PROFILE_TYPE_LEAD
          "
        >
          <b-row class="justify-content-center m-1" v-if="hasInterview">
            <b-button
              @click="openInterview(true)"
              target="_blank"
              variant="outline-primary"
              block
            >
              Editar entrevista
            </b-button>
          </b-row>
          <b-row
            class="justify-content-center m-1"
            v-if="!hasInterview && hasAppointment"
          >
            <b-button
              @click="convertInterview()"
              target="_blank"
              variant="outline-primary"
              block
            >
              Transformar em entrevista
            </b-button>
          </b-row>
          <b-row
            class="justify-content-center m-1"
            v-if="hasPreInterview || hasAppointment"
          >
            <b-button
              @click="openInterview(false)"
              target="_blank"
              variant="outline-primary"
              block
            >
              Visualizar entrevista
            </b-button>
          </b-row>
          <b-row class="justify-content-center m-1">
            <b-button
              @click="createAppointment"
              variant="outline-primary"
              block
            >
              Agendar reunião
            </b-button>
          </b-row>
          <b-row class="justify-content-center m-1" v-if="!hasInterview">
            <b-button
              variant="outline-primary"
              :disabled="loadingSendInterviewByWhatsapp"
              block
              @click="sendInterviewByWhatsapp"
            >
              <template v-if="loadingSendInterviewByWhatsapp">
                <b-spinner v-if="loadingSendInterviewByWhatsapp" small />
                Aguarde...
              </template>
              <template v-else>
                <span v-if="hasPreInterview">Re-enviar</span>
                <span v-else>Enviar</span>
                pré-entrevista por whatsapp
              </template>
            </b-button>
          </b-row>
          <b-row class="justify-content-center m-1" v-if="!hasInterview">
            <b-button
              variant="outline-primary"
              :disabled="loadingSendInterviewByEmail"
              block
              @click="sendInterviewByEmail"
            >
              <template v-if="loadingSendInterviewByEmail">
                <b-spinner v-if="loadingSendInterviewByEmail" small />
                Aguarde...
              </template>
              <template v-else>
                <span v-if="hasPreInterview">Re-enviar</span>
                <span v-else>Enviar</span>
                pré-entrevista por email
              </template>
            </b-button>
          </b-row>
          <b-row
            class="justify-content-center m-1"
            v-if="this.user.customer_type_id == 2"
          >
            <b-button variant="outline-primary" block @click="generateXMoment">
              <template> Apresentação do Momento X </template>
            </b-button>
          </b-row>
        </template>
        <template
          v-if="
            profileType == type.PROFILE_TYPE_CONSULTANT &&
            user.customer_status_id !=
              status.CUSTOMER_STATUS_LEAD_PN_TRANSFORMED
          "
        >
          <b-row class="justify-content-center m-1">
            <b-button
              @click="createAppointment"
              variant="outline-primary"
              block
            >
              {{
                profileType == type.PROFILE_TYPE_CONSULTANT
                  ? "Agendar uma entrevista individual"
                  : "Agendar uma entrevista"
              }}
            </b-button>
          </b-row>
          <b-row class="justify-content-center m-1">
            <b-button
              @click="openNewConsultantSidebar"
              variant="outline-primary"
              block
            >
              Transformar em PN
            </b-button>
          </b-row>
        </template>
        <b-row id="actions" class="justify-content-between m-1 mt-2">
          <b-col cols="12" class="px-0">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12" class="mb-1">
                    <span>Contato</span>
                  </b-col>
                  <b-col cols="auto" class="mb-1">
                    <b-button
                      variant="outline-whats"
                      size="sm"
                      @click="openWhatsapp"
                    >
                      <img
                        src="~@/assets/images/svg/icn_green_whats.svg"
                        width="15"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="auto" class="pl-0 mb-1">
                    <b-button
                      v-if="user.email"
                      variant="outline-email"
                      size="sm"
                      @click="sendEmail"
                    >
                      <img
                        src="~@/assets/images/svg/icn_email.svg"
                        width="15"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12" class="mb-1">
                    <span>Lembrar</span>
                  </b-col>
                  <b-col cols="auto">
                    <div class="text-wrap">
                      <b-form-input
                        @blur="saveCallReminder(call_reminder, user.id)"
                        class="form-control"
                        type="datetime-local"
                        v-model="call_reminder"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <template v-if="canChangeStatus">
            <b-col cols="12" class="px-0">
              <span>Alterar status</span>
            </b-col>
            <b-col cols="12" class="px-0">
              <b-row class="mt-1">
                <template
                  v-if="
                    profileType == type.PROFILE_TYPE_CUSTOMER ||
                    profileType == type.PROFILE_TYPE_LEAD
                  "
                >
                  <b-col cols="auto">
                    <b-button
                      id="customer-hot"
                      variant="outline-danger"
                      size="sm"
                      @click="setStatus(status.CUSTOMER_STATUS_HOT)"
                    >
                      <img
                        src="~@/assets/images/svg/icn_hot_active.svg"
                        width="15"
                      />
                    </b-button>
                    <b-tooltip
                      target="customer-hot"
                      title="Esquentar"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      id="customer-cold"
                      variant="outline-cold"
                      size="sm"
                      @click="setStatus(status.CUSTOMER_STATUS_COLD)"
                    >
                      <img
                        src="~@/assets/images/svg/icn_cold_active.svg"
                        width="15"
                      />
                    </b-button>
                  </b-col>
                  <b-tooltip
                    target="customer-cold"
                    title="Esfriar"
                    placement="top"
                    boundary="document"
                  ></b-tooltip>
                  <b-col cols="auto">
                    <b-button
                      id="customer-disinterested"
                      variant="outline-warning"
                      size="sm"
                      @click="setStatus(status.CUSTOMER_STATUS_DISINTERESTED)"
                    >
                      <img
                        src="~@/assets/images/svg/icn_disinterested_active.svg"
                        width="15"
                      />
                    </b-button>
                    <b-tooltip
                      target="customer-disinterested"
                      title="Desinteressado"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                </template>
                <template v-if="profileType == type.PROFILE_TYPE_CONSULTANT">
                  <b-col cols="auto">
                    <b-button
                      id="pn-hot"
                      variant="outline-danger"
                      size="sm"
                      @click="setStatus(status.CUSTOMER_STATUS_LEAD_PN_HOT)"
                    >
                      <img
                        src="~@/assets/images/svg/icn_hot_active.svg"
                        width="15"
                      />
                    </b-button>
                    <b-tooltip
                      target="pn-hot"
                      title="Esquentar"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      id="pn-disinterested"
                      variant="outline-warning"
                      size="sm"
                      @click="
                        setStatus(status.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED)
                      "
                    >
                      <img
                        src="~@/assets/images/svg/icn_disinterested_active.svg"
                        width="15"
                      />
                    </b-button>
                    <b-tooltip
                      target="pn-disinterested"
                      title="Desinteressado"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      id="pn-solides"
                      variant="outline-solides"
                      size="sm"
                      @click="setStatus(status.CUSTOMER_STATUS_LEAD_PN_SOLIDES)"
                    >
                      <img
                        src="~@/assets/images/svg/icn_solides.svg"
                        width="15"
                        style="transform: scale(2.2)"
                      />
                    </b-button>
                    <b-tooltip
                      target="pn-solides"
                      title="Solides"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      id="pn-disqualified"
                      variant="outline-primary"
                      size="sm"
                      @click="
                        setStatus(status.CUSTOMER_STATUS_LEAD_PN_DISQUALIFIED)
                      "
                    >
                      <feather-icon icon="XIcon" size="16" />
                    </b-button>
                    <b-tooltip
                      target="pn-disqualified"
                      title="Desclassificado"
                      placement="top"
                      boundary="document"
                    ></b-tooltip>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </template>
        </b-row>
      </b-card>
      <b-row class="card-separator">
        <b-col md="8">
          <img src="~@/assets/images/svg/icn_blue_user.svg" width="14" />
          Detalhes pessoais
        </b-col>
        <b-col md="4" class="text-right">
          <b-button
            class="btn-icon"
            variant="flat"
            style="padding: 0"
            @click="editPersonalData"
          >
            <img src="~@/assets/images/svg/icn_pencil.svg" width="14" />
          </b-button>
        </b-col>
      </b-row>
      <b-card
        class="about-card abbout-card-middle"
        v-if="
          profileType == type.PROFILE_TYPE_CUSTOMER ||
          profileType == type.PROFILE_TYPE_LEAD
        "
      >
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">CPF:</b-col>
          <b-col md="6" class="text-right">
            {{ user.cpf | cpfCnpj }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">RG:</b-col>
          <b-col md="6" class="text-right">
            {{ user.rg }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Data de Nascimento:</b-col>
          <b-col md="6" class="text-right">
            {{ user.birth_date | onlyDate }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Profissão:</b-col>
          <b-col md="6" class="text-right">
            {{ user.occupation }}
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6" class="text-muted">Faixa de Renda:</b-col>
          <b-col md="6" class="text-right">
            <span
              v-if="
                user.customer_additionals &&
                user.customer_additionals.income_bracket
              "
            >
              {{ user.customer_additionals.income_bracket }}
            </span>
            <span
              v-else-if="
                user.customer_additionals &&
                user.customer_additionals.income &&
                user.customer_additionals.income.name
              "
            >
              {{ user.customer_additionals.income.name }}
            </span>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        class="about-card abbout-card-middle"
        v-if="profileType == type.PROFILE_TYPE_CONSULTANT && leadPn"
      >
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Potencial:</b-col>
          <b-col md="6" class="text-right">
            {{ leadPn.consultant_lead_potential }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Profissão:</b-col>
          <b-col md="6" class="text-right" v-if="leadPn.occupation">
            {{ leadPn.occupation.name }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Formação:</b-col>
          <b-col md="6" class="text-right" v-if="leadPn.school_level">
            {{ leadPn.school_level.name }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Objetivo:</b-col>
          <b-col md="6" class="text-right">
            {{ leadPn.goal | leadGoal }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Consultor responsável:</b-col>
          <b-col
            md="6"
            class="text-right text-capitalize"
            v-if="leadPn.consultant"
          >
            {{ leadPn.consultant.name }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Franquia:</b-col>
          <b-col md="6" class="text-right" v-if="leadPn.consultant">
            {{ leadPn.consultant.leader_franchise_name }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Já empreendeu:</b-col>
          <b-col md="6" class="text-right">
            {{ leadPn.was_entrepreneur ? "Sim" : "Não" }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Origem:</b-col>
          <b-col md="6" class="text-right">
            {{ leadPn.origin | leadOrigin }}
          </b-col>
        </b-row>
      </b-card>
      <b-row class="card-separator">
        <b-col md="8">
          <feather-icon icon="UsersIcon" size="18" />
          Dono do cliente
        </b-col>
      </b-row>
      <b-card
        class="about-card abbout-card-middle"
        v-if="
          profileType == type.PROFILE_TYPE_CUSTOMER ||
          profileType == type.PROFILE_TYPE_LEAD
        "
      >
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Nome:</b-col>
          <b-col md="6" class="text-right">
            {{ user.consultant_name }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Nível:</b-col>
          <b-col md="6" class="text-right">
            {{ user.consultant_role }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Franquia:</b-col>
          <b-col md="6" class="text-right">
            {{ user.consultant_franchise_name }}
          </b-col>
        </b-row>
      </b-card>
      <b-row class="card-separator">
        <b-col md="6">
          <img src="~@/assets/images/svg/icn_local.svg" width="14" />
          Localização
        </b-col>
        <b-col md="6" class="text-right">
          <b-button
            class="btn-icon"
            variant="flat"
            style="padding: 0"
            @click="editAddress"
          >
            <img src="~@/assets/images/svg/icn_pencil.svg" width="14" />
          </b-button>
        </b-col>
      </b-row>
      <b-card class="about-card abbout-card-bottom">
        <template
          v-if="
            profileType == type.PROFILE_TYPE_CUSTOMER ||
            profileType == type.PROFILE_TYPE_LEAD
          "
        >
          <b-row class="mb-1 justify-content-left">
            <b-col md="12" class="text-muted">Endereço:</b-col>
            <b-col md="12">
              {{ user.address_street }}, {{ user.address_number }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="6" class="text-muted">CEP:</b-col>
            <b-col md="6" class="text-right">
              {{ user.address_cep | cep }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="6" class="text-muted">Bairro:</b-col>
            <b-col md="6" class="text-right">
              {{ user.address_district }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="12" class="text-muted">Complemento:</b-col>
            <b-col md="12">
              {{ user.address_complement }}
            </b-col>
          </b-row>
        </template>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Cidade:</b-col>
          <b-col md="6" class="text-right">
            {{ user.address_city }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6" class="text-muted">Estado:</b-col>
          <b-col md="6" class="text-right">
            {{ user.address_state }}
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
  BFormFile,
  BTooltip,
} from "bootstrap-vue";
import UserAddressSidebar from "../components/UserAddressSidebar";
import UserPersonalDataSidebar from "../components/UserPersonalDataSidebar";
import { useToast } from "vue-toastification/composition";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import NewAppointmentSidebar from "@/modules/appointment/components/NewAppointmentSidebar";
import NewLeadSidebar from "@/modules/lead/components/NewLeadSidebar";
import SaveConsultantBasicInformationSidebar from "@/modules/consultant/components/SaveConsultantBasicInformationSidebar";
import * as appointmentTypes from "@/modules/appointment/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as customerStatus from "@/constants/customers_status";
import * as leadTypes from "@/modules/lead/store/types";
import { createWppInterviewMessage } from "@/helpers/whatsapp_messages";
import * as profileTypes from "@/constants/profile";
import * as customerTypes from "@/constants/customers_types";
import * as consultantTypes from "@/modules/consultant/store/types";
import { LEAD_TYPE_PPN } from "@/constants/lead";
import useAppConfig from "@core/app-config/useAppConfig";
import { SET_CALL_REMINDER } from "../../customer/store/types";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    BSpinner,
    BFormFile,
    UserAddressSidebar,
    UserPersonalDataSidebar,
    NewAppointmentSidebar,
    NewLeadSidebar,
    SaveConsultantBasicInformationSidebar,
    BTooltip,
  },
  props: {
    profileType: String,
    reload: Function,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      userType: types.CUSTOMER,
      frontEndUrl: process.env.VUE_APP_FRONT_END_URL,
      appifyUrl: process.env.VUE_APP_URL,
      profilePic: undefined,
      loadingSendInterviewByWhatsapp: false,
      loadingSendInterviewByEmail: false,
      leadPn: undefined,
      call_reminder: undefined,
    };
  },
  computed: {
    ...mapGetters({
      user: types.CUSTOMER,
    }),
    status: function () {
      return customerStatus;
    },
    hasPreInterview: function () {
      return this.user.interviews?.length > 0;
    },
    hasInterview: function () {
      return (
        this.user.interviews?.length > 0 && this.user.interviews[0].is_interview
      );
    },
    hasAppointment: function () {
      return this.user.appointment?.length > 0;
    },
    type: function () {
      return profileTypes;
    },
    canChangeStatus: function () {
      if (
        this.user?.customer_status_id == customerStatus.CUSTOMER_STATUS_EA ||
        this.user?.customer_status_id == customerStatus.CUSTOMER_STATUS_EF ||
        this.user?.customer_status_id == customerStatus.CUSTOMER_STATUS_EP ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_SUPPORT ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_BASIC_SUB ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_ESSENTIAL_SUB ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_ELITE_SUB ||
        this.user?.customer_type_id == customerTypes.CUSTOMER ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_LEAD_PN_TRANSFORMED ||
        this.user?.customer_status_id ==
          customerStatus.CUSTOMER_STATUS_LEAD_PN_DISQUALIFIED
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getProfileData();
  },
  methods: {
    ...mapActions({
      getCustomer: types.GET_CUSTOMER,
      sendWhatsappInterview: sharedTypes.SEND_INTERVIEW_WHATSAPP,
      sendInterviewEmail: sharedTypes.SEND_INTERVIEW_EMAIL,
      openUserPersonalDataSidebar: types.OPEN_USER_PERSONAL_DATA_SIDEBAR,
      openUserAddressSidebar: types.OPEN_USER_ADDRESS_SIDEBAR,
      updateProfilePic: types.UPDATE_CUSTOMER_PROFILE_PICTURE,
      getInterviewToken: sharedTypes.GET_INTERVIEW_TOKEN,
      setCustomerLeadStatus: leadTypes.SET_CUSTOMER_LEAD_STATUS,
      getCustomerTimelineEvents: types.GET_CUSTOMER_TIMELINE_EVENTS,
      convertToInterview: types.CONVERT_TO_INTERVIEW,
      openNewAppointmentSidebar: appointmentTypes.OPEN_NEW_APPOINTMENT_SIDEBAR,
      getXMomentPDF: sharedTypes.GET_BUSINESS_CONFIG_X_MOMENT_PDF,
      getLeadPn: types.GET_LEAD_PN,
      openNewLeadSidebar: leadTypes.OPEN_NEW_LEAD_SIDEBAR,
      openSaveConsultantBasicInformationSidebar:
        consultantTypes.OPEN_NEW_CONSULTANT_SIDEBAR,
      setCallReminder: SET_CALL_REMINDER,
    }),
    getProfileData() {
      this.loading = true;
      this.getCustomer(this.$route.params.id)
        .then((response) => {
          if (response.data && response.data.customer_additionals) {
            this.call_reminder =
              response.data.customer_additionals.call_reminder;
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          if (this.profileType == profileTypes.PROFILE_TYPE_CUSTOMER) {
            this.$router.push({ name: "customers-list" });
          } else if (this.profileType == profileTypes.PROFILE_TYPE_LEAD) {
            this.$router.push({ name: "leads-customers-list" });
          }
        })
        .finally(() => {
          this.loading = false;
        });
      if (this.profileType == profileTypes.PROFILE_TYPE_CONSULTANT) {
        this.loading = true;
        this.getLeadPn(this.$route.params.id)
          .then((response) => {
            this.leadPn = response.data;
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao obter as informações. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    sendInterviewByWhatsapp() {
      this.loadingSendInterviewByWhatsapp = true;
      this.sendWhatsappInterview(this.user.token)
        .then((response) => {
          var message = createWppInterviewMessage(
            this.user.name,
            this.user.token,
            response.data.token_public
          );
          setTimeout(() => {
            window.open(
              "https://api.whatsapp.com/send?phone=" +
                this.user.cell_phone +
                "&text=" +
                message,
              "_blank"
            );
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao enviar a mensagem. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingSendInterviewByWhatsapp = false;
        });
    },
    sendInterviewByEmail() {
      if (this.user.email) {
        this.loadingSendInterviewByEmail = true;
        this.sendInterviewEmail(this.user.token)
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso!",
                text: "E-mail enviado com sucesso.",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao enviar o email. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loadingSendInterviewByEmail = false;
          });
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "O email para envio é inválido ou não existe. Por favor corrija o email antes de efetuar essa operação.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    generateXMoment() {
      this.getXMomentPDF().then((resp) => {
        setTimeout(() => {
          window.open(resp.data.value).focus();
        });
      });
    },
    openWhatsapp() {
      setTimeout(() => {
        window.open("https://wa.me/" + this.user.cell_phone, "_blank").focus();
      });
    },
    sendEmail() {
      location.href = "mailto:" + this.user.email;
    },
    editPersonalData() {
      if (this.profileType == profileTypes.PROFILE_TYPE_CONSULTANT) {
        this.openEditLeadPntSidebar();
      } else {
        this.openUserPersonalDataSidebar({
          user: this.user,
          saveAction: () => {
            this.getProfileData();
          },
        }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao editar os dados pessoais. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    editAddress() {
      if (this.profileType == profileTypes.PROFILE_TYPE_CONSULTANT) {
        this.openEditLeadPntSidebar();
      } else {
        this.openUserAddressSidebar({
          user: this.user,
          saveAction: () => {
            this.getProfileData();
          },
        }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao editar a localização. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    uploadPic() {
      if (this.profilePic) {
        const request = new FormData();
        request.append("id", this.user.id);
        request.append("picture", this.profilePic);
        this.updateProfilePic(request)
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Foto atualizada com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.getProfileData();
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar a fotos. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    openInterview(edit) {
      this.getInterviewToken()
        .then((response) => {
          const accessToken = response.data;
          if (edit) {
            setTimeout(() => {
              window
                .open(
                  this.frontEndUrl +
                    "/autenticacao/" +
                    accessToken +
                    "?back_url=entrevista/" +
                    this.user.token,
                  "_blank"
                )
                .focus();
            });
          } else {
            setTimeout(() => {
              window
                .open(
                  this.frontEndUrl +
                    "/autenticacao/" +
                    accessToken +
                    "?back_url=detalhe_entrevista/" +
                    this.user.token,
                  "_blank"
                )
                .focus();
            });
          }
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao tentar acessar a entrevista. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    setStatus(new_status) {
      if (this.user.customer_status_id == new_status) {
        new_status = customerStatus.CUSTOMER_STATUS_UNDEFINED;
      }
      if (this.canChangeStatus) {
        this.updateStatus(new_status);
      }
    },
    updateStatus(status_id) {
      this.setCustomerLeadStatus({
        customer_id: this.user.id,
        status_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.getProfileData();
          this.getCustomerTimelineEvents(this.user.id).catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as atividades recentes. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao atualizar o status. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    convertInterview() {
      this.$swal({
        title: "Tem certeza?",
        text: `Após tranformar em entrevista o cliente não terá mais acesso, e não poderá reverter isso!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.convertToInterview(this.user.token)
            .then((result) => {
              this.openInterview(true);
              this.reload();
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao tranformar em entrevista. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    createAppointment() {
      this.openNewAppointmentSidebar({
        customer_id: this.user.id,
        saveAction: () => {
          this.reload();
        },
      });
    },
    openEditLeadPntSidebar() {
      this.openNewLeadSidebar({
        id: this.user.id,
        leadType: LEAD_TYPE_PPN,
        saveAction: () => {
          this.getProfileData();
          this.getCustomerTimelineEvents(this.user.id).catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as atividades recentes. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
        },
      }).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao editar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    openNewConsultantSidebar() {
      this.openSaveConsultantBasicInformationSidebar({
        customer_id: this.user.id,
      }).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao tentar transformar o lead PN. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    saveCallReminder(date, customer_id) {
      this.setCallReminder({
        date,
        customer_id,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Atualizado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao escolher a data para lembrar. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.about-card {
  margin: unset !important;
}

.abbout-card-top {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.abbout-card-middle {
  border-radius: unset;
}

.abbout-card-bottom {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.card-separator {
  margin-top: 25px;
  margin-left: 2px;
  margin-bottom: 15px;
}

#actions button {
  height: 35px !important;
}

.b-avatar-img {
  background-color: white;
}

.dark-layout.b-avatar-img {
  background-color: transparent;
}
</style>
<style lang="scss">
.profile .b-avatar-img {
  background-color: white;
}
body.dark-layout .profile .b-avatar-img {
  background-color: transparent;
}
.img_icon {
  width: 15px;
  height: 15px;
}
.vip-badge {
  border: solid 1px #dba800;
  background: unset;
} 
.badge {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
