<template>
  <div>
    <comment-sidebar />
    <b-card class="comment-card-top">
      <b-row>
        <b-col>
          <h4>Últimos comentários</h4>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="9">
          <b-form-textarea
            v-model="newComment"
            placeholder="Insira o comentário..."
          />
        </b-col>
        <b-col md="3">
          <b-button variant="primary" @click="saveComment">
            Inserir comentário
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <div v-else>
      <b-card class="comment-card-bottom">
        <template v-if="items.length > 0">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="scrollable-container scroll-area profile-scroller"
            tagname="div"
          >
            <b-list-group flush v-if="items.length > 0">
              <b-list-group-item
                tag="li"
                class="item-no-click"
                v-for="(item, index) in items"
                :key="item.id"
              >
                <b-row>
                  <b-col md="8">
                    <b-avatar
                      src="~@/assets/images/logo/futuro.svg"
                      size="2rem"
                      class="mr-1"
                    />
                    <span v-if="item.added_by">
                      <b>{{ item.added_by.name }}</b>
                    </span>
                    <span v-else>
                      <b>{{ item.name }}</b>
                    </span>
                    - {{ item.formatedDate }}
                  </b-col>
                  <b-col class="text-right mr-1">
                    <b-button
                      class="btn-icon"
                      variant="flat"
                      style="padding: 0"
                      @click="edit(index)"
                    >
                      <img
                        class="img_icon mr-1"
                        src="~@/assets/images/svg/icn_pencil.svg"
                        width="14"
                      />
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="flat"
                      style="padding: 0"
                      @click="remove(index)"
                    >
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_trash.svg"
                        width="14"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="ml-3">
                    <span class="text-muted" v-if="item.interview_session">
                      {{ item.interview_session | interviewSessionName }}
                      (entrevista)
                      <br />
                    </span>
                    <span style="white-space: pre-wrap">
                      {{ item.comment }}
                    </span>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </template>
        <template v-else>
          <span class="text-muted">Nenhum comentário encontrado...</span>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BMedia,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import CommentSidebar from "@/modules/shared/components/CommentSidebar";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    VuePerfectScrollbar,
    BMedia,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BSpinner,
    CommentSidebar,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      newComment: undefined,
    };
  },
  computed: {
    ...mapGetters({
      customerComments: types.CUSTOMER_COMMENTS,
    }),
    items: function () {
      var comments = [];
      if (this.customerComments) {
        if (this.customerComments.interview) {
          comments.push(...this.customerComments.interview);
        }
        if (this.customerComments.comments) {
          comments.push(...this.customerComments.comments);
        }
        if (this.customerComments.observation) {
          comments.unshift(this.customerComments.observation);
        }

        comments = comments.map(function (element) {
          var utcDate = new Date(element.updated_at);
          var options = {
            timeZone: "America/Sao_Paulo",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }; // Specify the Brazil time zone
          element.formatedDate = utcDate
            .toLocaleString("pt-BR", options)
            .replace(",", "");
          return element;
        });

        comments = _.orderBy(
          comments,
          function (element) {
            return element.updated_at;
          },
          ["desc"]
        );
      }
      return comments;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getCustomerComments: types.GET_CUSTOMER_COMMENTS,
      saveNewComment: types.SAVE_NEW_COMMENT,
      openCommentSidebar: sharedTypes.OPEN_COMMENT_SIDEBAR,
      updateComment: types.UPDATE_COMMENT,
      deleteComment: types.DELETE_COMMENT,
    }),
    get() {
      this.loading = true;
      this.getCustomerComments(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar o usuário selecionado. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveComment() {
      if (this.newComment) {
        this.saveNewComment({
          customer_id: this.$route.params.id,
          comment: this.newComment,
        })
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "O comentário foi salvo com sucesso",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.newComment = undefined;
            this.get();
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o comentário. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    edit(index) {
      this.openCommentSidebar({
        id: index,
        title: "Editar comentário",
        comment: this.items[index].comment,
        saveAction: this.update,
      });
    },
    async update(obj) {
      const { id, comment_type } = this.items[obj.id];
      await this.updateComment({
        id,
        comment_type,
        customer_id: this.$route.params.id,
        comment: obj.comment,
      })
        .then((resp) => {
          this.get();
          return resp;
        })
        .catch((err) => {
          return err;
        });
    },
    remove(index) {
      this.$swal({
        title: "Confirmação",
        text: `Deseja remover o comentário?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          const { id, comment_type } = this.items[index];
          this.deleteComment({
            id,
            comment_type,
            customer_id: this.$route.params.id,
          })
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: "O comentário foi removido com sucesso",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.get();
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao remover o comentário. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-card-top {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
.comment-card-bottom {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
.profile-scroller {
  max-height: 30rem;
}
.item-no-click:active {
  background-color: transparent !important;
}
</style>
