<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <b-card v-else>
      <b-card-title> Resumo dos contratos </b-card-title>
      <b-table
        hover
        :items="items"
        borderless
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados contratos para o cliente"
        :busy="loading"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: index -->
        <template #cell(contract_status)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.contract_status.name
            }}</span>
          </div>
        </template>
        <!-- Column: consultant -->
        <template #cell(financial_solution)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.financial_solution.name
            }}</span>
          </div>
        </template>
        <!-- Column: support -->
        <template #cell(gross_value)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.gross_value | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: date -->
        <template #cell(monthly_premium)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.monthly_premium | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: status -->
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.created_at | dateTime
            }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as types from "../store/types";

export default {
  components: {
    BCard,
    BCardTitle,
    BPagination,
    BTable,
    BSpinner,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "contract_status", label: "Status", sortable: true },
        {
          key: "financial_solution",
          label: "Solução Financeira",
          sortable: true,
        },
        { key: "gross_value", label: "LTV Bruto", sortable: true },
        { key: "monthly_premium", label: "Prêmio Mensal", sortable: true },
        { key: "created_at", label: "Data de Atualização", sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerContracts: types.CUSTOMER_CONTRACTS,
    }),
    items: function () {
      return this.customerContracts;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getCustomerContracts: types.GET_CUSTOMER_CONTRACTS,
    }),
    goToContractDetails(contract, index) {
      var routeData = this.$router.resolve({
        name: "contract-details-summary",
        params: { id: contract.id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    get() {
      this.loading = true;
      this.getCustomerContracts(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os contratos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
