<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <b-card v-else-if="items.length">
      <b-card-header class="p-0 mb-1">
        <b-card-title> Lista de recomendações </b-card-title>
        <b-col cols="12" md="6">
          <b-row class="align-items-center justify-content-end">
            <b-button variant="primary" @click="sendListToCustomer()">
              <!-- <feather-icon icon="SendIcon" class="mr-50" /> -->
              <img
                class="img-whats mr-50"
                src="~@/assets/images/svg/icn_green_whats.svg"
                width="15"
              />
              <span class="text-nowrap">Enviar lista ao cliente</span>
            </b-button>
          </b-row>
        </b-col>
      </b-card-header>

      <b-table
        :items="items"
        striped
        borderless
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        show-empty
        :per-page="itemsPerPage"
        empty-text="Não foram encontrados recomendações"
        :busy="loading"
      >
        <template #cell(customer_status)="data">
          <div class="text-center">
            <b-badge
              pill
              variant="hot-user"
              v-if="data.item.customer_status.id == status.CUSTOMER_STATUS_HOT"
            >
              <img src="~@/assets/images/svg/icn_hot_active.svg" width="10" />
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="cold-user"
              v-else-if="
                data.item.customer_status.id == status.CUSTOMER_STATUS_COLD
              "
            >
              <img src="~@/assets/images/svg/icn_cold_active.svg" width="10" />
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="done_interview-user"
              v-else-if="
                data.item.customer_status.id == status.CUSTOMER_STATUS_EP
              "
            >
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="disinterested-user"
              v-else-if="
                data.item.customer_status.id ==
                status.CUSTOMER_STATUS_DISINTERESTED
              "
            >
              <img
                src="~@/assets/images/svg/icn_disinterested_active.svg"
                width="12"
              />
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="scheduled_interview-user"
              v-else-if="
                data.item.customer_status.id == status.CUSTOMER_STATUS_EA
              "
            >
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="done_interview-user"
              v-else-if="
                data.item.customer_status.id == status.CUSTOMER_STATUS_EF
              "
            >
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge
              pill
              variant="wait_contract_payment-user"
              v-else-if="
                data.item.customer_status.id ==
                status.CUSTOMER_STATUS_WAIT_CONTRACT_PAYMENT
              "
            >
              {{ data.item.customer_status.name }}
            </b-badge>
            <b-badge pill variant="undefined-user" v-else>
              {{ data.item.customer_status.name }}
              <span v-if="!data.item.customer_status.name">
                A classificar
              </span>
            </b-badge>
          </div>
        </template>

        <template #cell(appointment)="data">
          <span>{{ bringMeTheDate(data.item.appointment) }}</span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="itemsPerPage"
        aria-controls="files-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BPagination,
  BSpinner,
  BBadge,
  BCardHeader,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as customerStatus from "@/constants/customers_status";
import moment from "moment";
import { createWppCustomerMessage } from "@/helpers/whatsapp_messages";

export default {
  components: {
    BCard,
    BCardTitle,
    BPagination,
    BTable,
    BSpinner,
    BBadge,
    BCardHeader,
    BButton,
    BRow,
    BCol,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 5,
      currentPage: 1,
      tableColumns: [
        { key: "name", label: "Recomendações", sortable: true },
        { key: "customer_status", label: "Status", sortable: true },
        {
          key: "appointment",
          label: "Data da reunião",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerRecommendations: types.CUSTOMER_RECOMMENDATIONS,
      customer: types.CUSTOMER,
    }),
    items: function () {
      return this.customerRecommendations?.data || [];
    },
    rows() {
      return this.items.length;
    },
    status: function () {
      return customerStatus;
    },
    customerRecs: function () {
      return this.customerRecommendations?.wpp_message_data || [];
    },
  },
  async mounted() {
    await this.get();
  },
  methods: {
    ...mapActions({
      getCustomerRecommendations: types.GET_CUSTOMER_RECOMMENDATIONS,
    }),
    async get() {
      try {
        this.loading = true;
        await this.getCustomerRecommendations(this.$route.params.id);
        // this.itemsPerPage = this.customerRecommendations?.per_page;
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as recomendações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loading = false;
      }
    },
    bringMeTheDate(date) {
      if (Array.isArray(date)) {
        const getDate = date.map((d) => d.appointment_start);
        const theDate =
          moment(getDate[0]).format("DD/MM/YYYY") +
          " às " +
          moment(getDate[0]).format("hh:mm");

        return date.length ? theDate : "-";
      }
    },
    sendListToCustomer() {
      const customerPhone = this.customer?.cell_phone;

      const recommendations = this.customerRecs.map((item) => {
        const name = item?.name;

        const appointmentFormat =
          moment(item?.appointment_start).format("DD/MM/YYYY") +
          " às " +
          moment(item?.appointment_start).format("hh:mm");

        const appointmentStart = item.appointment_start
          ? appointmentFormat
          : "Sem reunião agendada";
        const cellPhone = item?.cell_phone;

        var message = createWppCustomerMessage(name, appointmentStart);

        return message;
      });

      setTimeout(() => {
        window.open(
          "https://api.whatsapp.com/send?phone=" +
            customerPhone +
            "&text=Sua lista de recomendações:%0a%0a" +
            recommendations.join("\n"),
          "_blank"
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img-whats {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(67deg)
    brightness(104%) contrast(336%);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
