var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center m-2"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):(_vm.items.length)?_c('b-card',[_c('b-card-header',{staticClass:"p-0 mb-1"},[_c('b-card-title',[_vm._v(" Lista de recomendações ")]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',{staticClass:"align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sendListToCustomer()}}},[_c('img',{staticClass:"img-whats mr-50",attrs:{"src":require("@/assets/images/svg/icn_green_whats.svg"),"width":"15"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Enviar lista ao cliente")])])],1)],1)],1),_c('b-table',{attrs:{"items":_vm.items,"striped":"","borderless":"","responsive":"","fields":_vm.tableColumns,"current-page":_vm.currentPage,"show-empty":"","per-page":_vm.itemsPerPage,"empty-text":"Não foram encontrados recomendações","busy":_vm.loading},scopedSlots:_vm._u([{key:"cell(customer_status)",fn:function(data){return [_c('div',{staticClass:"text-center"},[(data.item.customer_status.id == _vm.status.CUSTOMER_STATUS_HOT)?_c('b-badge',{attrs:{"pill":"","variant":"hot-user"}},[_c('img',{attrs:{"src":require("@/assets/images/svg/icn_hot_active.svg"),"width":"10"}}),_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id == _vm.status.CUSTOMER_STATUS_COLD
            )?_c('b-badge',{attrs:{"pill":"","variant":"cold-user"}},[_c('img',{attrs:{"src":require("@/assets/images/svg/icn_cold_active.svg"),"width":"10"}}),_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id == _vm.status.CUSTOMER_STATUS_EP
            )?_c('b-badge',{attrs:{"pill":"","variant":"done_interview-user"}},[_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id ==
              _vm.status.CUSTOMER_STATUS_DISINTERESTED
            )?_c('b-badge',{attrs:{"pill":"","variant":"disinterested-user"}},[_c('img',{attrs:{"src":require("@/assets/images/svg/icn_disinterested_active.svg"),"width":"12"}}),_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id == _vm.status.CUSTOMER_STATUS_EA
            )?_c('b-badge',{attrs:{"pill":"","variant":"scheduled_interview-user"}},[_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id == _vm.status.CUSTOMER_STATUS_EF
            )?_c('b-badge',{attrs:{"pill":"","variant":"done_interview-user"}},[_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):(
              data.item.customer_status.id ==
              _vm.status.CUSTOMER_STATUS_WAIT_CONTRACT_PAYMENT
            )?_c('b-badge',{attrs:{"pill":"","variant":"wait_contract_payment-user"}},[_vm._v(" "+_vm._s(data.item.customer_status.name)+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"undefined-user"}},[_vm._v(" "+_vm._s(data.item.customer_status.name)+" "),(!data.item.customer_status.name)?_c('span',[_vm._v(" A classificar ")]):_vm._e()])],1)]}},{key:"cell(appointment)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.bringMeTheDate(data.item.appointment)))])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.itemsPerPage,"aria-controls":"files-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }