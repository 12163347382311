<template>
  <div>
    <b-sidebar
      id="sidebar-new-lead"
      sidebar-class="sidebar-lg"
      :visible="uploadFileSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateUploadFileSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Enviar arquivos</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" class="mt-1">
              <file-chooser
                id="company-address-file"
                label="Envie seus arquivos"
                v-model="resumeFile"
                :url="resumeUrl"
                :disabled="saving"
                :thereIsError="v$.resumeFile.$error && v$.resumeFile.$invalid"
                @change="onFileSelected"
                multiple
              />
              <div class="invalid-size" v-if="fileSizeError">
                <span>O envio de arquivos não pode ser maior que 10 MB.</span>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="lead-buttons"
            >
              Voltar
            </b-button>
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormFile,
  BCol,
  BRow,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import FileChooser from "@/modules/shared/components/FileChooser";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    DynamicSelect,
    FileChooser,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      resumeFile: undefined,
      resumeUrl: undefined,
      changeResumeFile: false,
      saving: false,
      fileSizeError: false,
      canSubmit: true,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      resumeFile: {
        required,
      },
      uploadFileSidebar: {
        id: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      uploadFileSidebar: types.UPLOAD_FILE_SIDEBAR,
      customerFiles: types.CUSTOMER_FILES,
    }),
    resumeFileName: function () {
      return this.resumeFile?.name;
    },
  },
  methods: {
    ...mapMutations({
      mutateUploadFileSidebar: types.MUTATE_UPLOAD_FILE_SIDEBAR,
      mutateCustomers: sharedTypes.MUTATE_CUSTOMERS,
    }),
    ...mapActions({
      storeUploadFile: types.STORE_UPLOAD_FILE,
    }),
    onFileSelected(event) {
      const files = event?.target?.files;
      if (files) {
        let totalSize = 0;
        for (let i = 0; i < files.length; i++) {
          totalSize += files[i].size;
        }
        if (totalSize > 10 * 1024 * 1024) {
          this.fileSizeError = true;
          this.canSubmit = false;
          return;
        } else {
          this.fileSizeError = false;
          this.canSubmit = true;
        }
      }
    },
    async onSubmit() {
      if (!this.canSubmit) {
        this.clear();
        return;
      }
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const payload = {
        customer_id: this.uploadFileSidebar?.id,
        files: this.resumeFile,
      };
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== undefined && value !== "" && value !== "null") {
          if (key === "files") {
            for (let i = 0; i < value.length; i++) {
              request.append("files[]", value[i]);
            }
          } else {
            request.append(key, value);
          }
        }
      }
      this.storeUploadFile(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Arquivo salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateUploadFileSidebar({ visible: false });
          this.uploadFileSidebar.saveAction();
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o arquivo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clear() {
      this.resumeFile = undefined;
      this.resumeUrl = undefined;
      this.changeResumeFile = false;
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  padding: 0.438rem 1rem;
}
</style>
<style lang="scss">
.sidebar-xg {
  width: 36rem;
}

.lead-buttons {
  width: 10rem;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar";
}

.resume-download-col {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.invalid-size {
  width: 100%;
  margin-top: -1rem;
  font-size: 0.857rem;
  color: #ea5455;
}
</style>
