<template>
  <div>
    <b-sidebar
      id="sidebar-edit-user-address"
      sidebar-class="sidebar-lg"
      :visible="userAddressSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateUserAddressSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Editar Localização</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="CEP"
                label-for="user-cep"
              >
                <b-form-input
                  id="user-cep"
                  v-model="address_cep"
                  v-mask="'##.###-###'"
                  @change="searchCep"
                  :class="{ 'is-invalid': v$.address_cep.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Estado"
                label-for="user-address-state"
              >
                <v-select
                  id="user-address-state"
                  v-model="address_state"
                  :reduce="address_state => address_state.id"
                  :options="addressStates"
                  :loading="loading.addressStates"
                  @input="addressStateChanged"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group
                label="Cidade"
                label-for="user-address-cities"
              >
                <v-select
                  id="user-address-cities"
                  v-model="address_city"
                  :reduce="address_city => address_city.id"
                  :options="addressCities"
                  :loading="loading.addressCities"
                  :disabled="!address_state"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Logradouro"
                label-for="user-address-street"
              >
                <b-form-input
                  id="user-address-street"
                  v-model="address_street"
                  :class="{ 'is-invalid': v$.address_street.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Número"
                label-for="user-address-number"
              >
                <b-form-input
                  id="user-address-number"
                  v-model="address_number"
                  :class="{ 'is-invalid': v$.address_number.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Bairro"
                label-for="user-address-district"
              >
                <b-form-input
                  id="user-address-district"
                  v-model="address_district"
                  :class="{ 'is-invalid': v$.address_district.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Complemento"
                label-for="user-address-complement"
              >
                <b-form-input
                  id="user-address-complement"
                  v-model="address_complement"
                  :class="{ 'is-invalid': v$.address_complement.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="edit-buttons"
            >
              Voltar
            </b-button>
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="edit-buttons"
            >
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BSidebar, 
  BForm, 
  BFormGroup, 
  BFormFile, 
  BButton, 
  BFormTextarea,
  BFormInput,
  BCol,
  BRow
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { onlyDigits } from '@/helpers/converters'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'


export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        addressStates: undefined,
        addressCities: undefined
      },
      address_cep: undefined,
      address_state: undefined,
      address_city: undefined,
      address_street: undefined,
      address_number: undefined,
      address_district: undefined,
      address_complement: undefined,
      saving: false,
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      address_cep: {},
      address_state: {},
      address_city: {},
      address_street: {},
      address_number: {},
      address_district: {},
      address_complement: {},
      userAddressSidebar: {
        user:{
          id: { required }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      userAddressSidebar: types.USER_ADDRESS_SIDEBAR,
      addressStates: sharedTypes.ADDRESS_STATES,
      addressCities: sharedTypes.ADDRESS_CITIES 
    })
  },
  mounted() {
    this.getAddressStates()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os estados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.addressStates = false
      })
  },
  methods: {
    ...mapMutations({
      mutateUserAddressSidebar: types.MUTATE_USER_ADDRESS_SIDEBAR
    }),
    ...mapActions({
      getAddressStates: sharedTypes.GET_ADDRESS_STATES,
      getAddressCities: sharedTypes.GET_ADDRESS_CITIES,
      searchCepAddress: sharedTypes.GET_ADDRESS_FROM_CEP,
      updateCustomerAddress: types.UPDATE_CUSTOMER_ADDRESS
    }),
    onShow(){
      var userData = this.userAddressSidebar.user
      if(!userData){
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao editar a localização. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }else{
        this.address_cep = userData.address_cep
        this.setState(userData.address_state)
          .then(() =>{
            this.setCity(userData.address_city)
          })
        this.address_city = userData.address_city
        this.address_street = userData.address_street
        this.address_number = userData.address_number
        this.address_district = userData.address_district
        this.address_complement = userData.address_complement
        this.v$.$touch()
      }
    },
    async onSubmit() {
      this.saving = true
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      const {
        address_cep,
        address_state,
        address_city,
        address_street,
        address_number,
        address_district,
        address_complement
      } = this
      const state = _.find(this.addressStates, {id: address_state})
      const city = _.find(this.addressCities, {id: address_city})
      this.updateCustomerAddress({
        id: this.userAddressSidebar.user.id,
        address_cep: onlyDigits(address_cep),
        address_state: state? state.uf:undefined,
        address_state_id: address_state,
        address_city: city? city.name:undefined,
        address_city_id: address_city,
        address_street,
        address_number,
        address_district,
        address_complement
      })
      .then(response =>{
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Sucesso!',
            text: 'Salvo com sucesso.',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
        this.mutateUserAddressSidebar({visible: false})
        this.userAddressSidebar.saveAction()
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao salvar. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.saving = false
      })
    },
    async setState(uf){
      var state = _.find(this.addressStates, {uf})
      if(state){
        this.address_state = state.id
        await this.addressStateChanged()
      }
    },
    setCity(name){
      var city = _.find(this.addressCities, {name})
      if(city){
        this.address_city = city.id
      }
    },
    async addressStateChanged(){
      this.address_city = undefined
      if(this.address_state){
        this.loading.addressCities = true
        await this.getAddressCities({addressState: this.address_state})
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as cidades para seleção. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
          .finally(() => {
            this.loading.addressCities = false
          });
      }
    },
    searchCep() {
      if(this.address_cep.length === 10) {
        this.searchCepAddress(onlyDigits(this.address_cep))
          .then(response => {
            this.address_street = response.data.logradouro
            this.address_district = response.data.bairro
            this.setState(response.data.uf)
              .then(() =>{
                this.setCity(response.data.localidade)
              })
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar ao buscar o cep informado. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      }
    },
    clear(){
      this.address_cep = undefined
      this.address_state = undefined
      this.address_city = undefined
      this.address_street = undefined
      this.address_number = undefined 
      this.address_district = undefined
      this.address_complement = undefined
      this.v$.$reset();
    }
  }
}
</script>

<style lang="scss">
.edit-buttons{
  width: 10rem;
}
</style>