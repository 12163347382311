<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <b-card v-else>
      <b-card-title>
        Histórico de Agendamentos
      </b-card-title>
      <b-table
        :items="items"
        striped
        borderless
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados agendamentos"
        :busy="loading"
      >
        <!-- Column: index -->
        <template #cell(index)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{data.index + 1}}</span>
          </div>
        </template>
        <!-- Column: consultant -->
        <template #cell(consultant)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{ data.item.consultant.name }}</span>
          </div>
        </template>
        <!-- Column: support -->
        <template #cell(support)="data">
          <div class="text-wrap">
            <span v-if="data.item.consultant_specialist" class="align-text-top text-capitalize">{{ data.item.consultant_specialist.name }}</span>
          </div>
        </template>
        <!-- Column: date -->
        <template #cell(date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{ data.item.appointment_start | dateTime }}</span>
          </div>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{ data.item.appointment_status.description }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BTable, BPagination, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as sharedTypes from '@/modules/shared/store/types'
import * as types from '../store/types'

export default {
  components: {
    BCard,
    BCardTitle,
    BPagination,
    BTable,
    BSpinner,
  },
  setup() {
    return { toast: useToast() }
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: 'index', label: '#', sortable: true},
        { key: 'consultant', label: 'PN responsável', sortable: true},
        { key: 'support', label: 'PNC Suporte', sortable: true},
        { key: 'date', label: 'Data', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
    }
  },
  computed: {
    ...mapGetters({
      customerAppointments: types.CUSTOMER_APPOINTMENTS
    }),
    items: function () {
      var Appointments = this.customerAppointments.map(function(element) {
          var utcDate = new Date(element.appointment_start);
          var options = { timeZone: 'America/Sao_Paulo' }; // Specify the Brazil time zone
          element.formatedDate = utcDate.toLocaleString('pt-BR', options).replace(',','');
          return element;
        });
      return Appointments
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    ...mapActions({
      getCustomerAppointments: types.GET_CUSTOMER_APPOINTMENTS
    }),
    get(){
      this.loading = true
      this.getCustomerAppointments(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar os agendamentos. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
